import React, { useState } from 'react';
import Condition from '../Condition';
import ListTable from '../ListTable';

export default function Detail() {
  const [conditions, setConditions] = useState(null);
  function handleSearch(_conditions) {
    setConditions(_conditions);
  }
  return (
    <div style={{ margin: '24px', backgroundColor: '#fff', padding: '24px' }}>
      <Condition
        onSearch={handleSearch}
      />
      <ListTable
        conditions={conditions}
      />
    </div>
  );
}
