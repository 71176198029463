import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon, Popover, Button, Pagination,
} from 'antd';
import './TableFooter.scss';

export default function TableFooter({
  popVisible, togglePopVisible, onPatchOnline, onPatchOffline,
  total, current, pageSize, onPageChange, selectedRowKeys
}) {
  return (
    <div className="traffic-table-footer">
      <div className="patch-update">
        <Popover
          className="traffic-patch-pop"
          visible={popVisible}
          trigger="click"
          title="批量操作"
          placement="topLeft"
          content={(
            <div className="traffic-patch-pop-content">
              <div role="button" tabIndex="0" onClick={onPatchOnline} style={{ outline: 'none', cursor: 'pointer' }}>
                <p className="traffic-patch-pop-action">
                  <span>
                    <Icon type="close-circle" style={{ color: '#F5222D' }} />
                  </span>
                  下线
                </p>
                <p className="tip">
                  <span />
                  批量下线产品
                </p>
              </div>
              <div role="button" tabIndex="0" onClick={onPatchOffline} style={{ outline: 'none', cursor: 'pointer' }}>
                <p className="traffic-patch-pop-action">
                  <span>
                    <Icon type="up-circle" style={{ color: '#37A9E7' }} />
                  </span>
                  上线
                </p>
                <p className="tip">
                  <span />
                  批量上线产品
                </p>
              </div>
            </div>
          )}
        >
          <Button onClick={togglePopVisible} disabled={selectedRowKeys.length === 0}>批量操作</Button>
        </Popover>
      </div>
      <Pagination
        className="traffic-table-pagination"
        total={total}
        current={current}
        pageSize={pageSize}
        onChange={onPageChange}
      />
    </div>
  );
}

TableFooter.propTypes = {
  popVisible: PropTypes.bool.isRequired,
  togglePopVisible: PropTypes.func.isRequired,
  onPatchOnline: PropTypes.func.isRequired,
  onPatchOffline: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};
