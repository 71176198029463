import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, Icon } from 'antd';
import logo from '../../assets/iamges/mi-logo-small.png';
import style from './index.module.scss';

export default function Sider({ collapsed }) {
  return (
    <Layout.Sider trigger={null} collapsible collapsed={collapsed}>
      <div className={style.nav}>
        <img src={logo} alt="小米" className={style.logo} />
        <span className={collapsed ? style.titleHide : style.title}>流量商户后台</span>
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          <Icon type="dashboard" />
          <span>产品管理</span>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
}

Sider.defaultProps = {
  collapsed: false,
};

Sider.propTypes = {
  collapsed: PropTypes.bool,
};
