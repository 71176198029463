import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message, Table, Divider } from 'antd';
import Infos from './Infos';
import UpdateModal from './UpdateModal';
import PatchModal from './PatchModal';
import TableFooter from './TableFooter';
import api from '../../api';
import operators from '../../config/operators.json';

const { Column } = Table;

function getOperatorsDesc(spType) {
  const sp = operators.find(item => item.value === spType);
  return (sp && sp.text) || spType;
}

function getDotSpan(text) {
  if (!text) return null;
  if (text === '上线') {
    return <span className="mi-little-circle green" />;
  }
  return <span className="mi-little-circle" />;
}

function fillOrder(order) {
  if (!Number.isInteger(order)) return order;
  if (order < 10) return `0${order}`;
  return `${order}`;
}

function fillData(dataSource, pageSize, current, rowKey) {
  const newDataSource = [];
  if (!dataSource || !dataSource.length) return newDataSource;
  for (let i = 0; i < pageSize; i++) {
    const order = fillOrder((current - 1) * pageSize + i + 1);
    if (dataSource && dataSource[i]) {
      newDataSource.push({
        ...dataSource[i],
        __miTableOrder: order,
        key: dataSource[i][rowKey],
      });
    } else {
      newDataSource.push({
        __miTableOrder: '',
        key: `__fake_rowKey_${order}`,
      });
    }
  }
  return newDataSource;
}

function ListTable({ conditions }) {
  // 表格数据
  const [reloadFlag, setReloadFlag] = useState(true);
  const [dataSource, setDataSource] = useState([]); // 数据源
  const [selectedRowKeysS, setSelectedRowKeysS] = useState([]); // 选中行数
  const [selectedRowsData, setSelectedRowsData] = useState([]); // 选中数据
  // 单行状态改变数据
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [updateModalData, setUpdateModalData] = useState(null);
  const [updateStatus, setaUpdateStatus] = useState(undefined);
  // 批量状态改变数据
  const [patchPopVisible, setPatchPopVisible] = useState(false);
  const [patchModalVisible, setPatchModalVisible] = useState(false);
  const [patchModalData, setPatchModalData] = useState(null);
  const [patchStatus, setPatchStatus] = useState(undefined);
  // 查询条件，包括分页信息
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const pageSize = 10;

  const rowKey = 'productNormalId';
  function reloadData() {
    // 刷新数据
    setReloadFlag(!reloadFlag);
    setSelectedRowKeysS([]);
    setSelectedRowsData([]);
  }

  useEffect(() => {
    if (!conditions) return;
    api.getList({
      ...conditions,
      pageSize,
      currPage: current,
    }).then((resData) => {
      setDataSource(resData.data.list);
      setTotal(resData.data.totalCount);
    }).catch((err) => {
      // message.error('获取列表出错！');
      // console.error('获取列表报错', err);
    });
  }, [conditions, current, reloadFlag]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeysS,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeysS(selectedRowKeys);
      setSelectedRowsData(selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: !record.productNormalId,
      name: record.name,
      class: 'emmmmm',
    }),
  };

  /**
   * @param {*} record 操作兑数据
   * @param {*} status 要变成的状态
   */
  function handleStatus(record, status) {
    setUpdateModalVisible(true);
    setUpdateModalData(record);
    setaUpdateStatus(status);
  }

  function handleOkUpdateModal(status) {
    let isOnline = '';
    if (status === 'online') {
      isOnline = '1';
    }
    if (status === 'offline') {
      isOnline = '2';
    }
    api.updateStatus({
      productNormalIds: updateModalData.productNormalId,
      isOnline,
    }).then(() => {
      message.success('操作成功！');
      setUpdateModalData(null);
      setUpdateModalVisible(false);
      reloadData();
    }).catch(() => {
      message.error('操作失败！');
    });
  }
  function handleCancelUpdateModal() {
    setUpdateModalVisible(false);
    setUpdateModalData(null);
  }

  function togglePatchPopVisible() {
    setPatchPopVisible(!patchPopVisible);
  }

  function handlePatchStatus(flag) {
    let rows = [];
    if (!selectedRowsData || !selectedRowsData.length) {
      setPatchPopVisible(false);
      return;
    }
    if (flag === 'online') {
      rows = selectedRowsData.filter(item => item.isOnline === '上线');
    }
    if (flag === 'offline') {
      rows = selectedRowsData.filter(item => item.isOnline === '下线');
    }
    setPatchModalVisible(true);
    setPatchModalData(rows);
    setPatchStatus(flag);
    setPatchPopVisible(false);
  }

  function handleOkPatchModal(status) {
    let isOnline = '';
    if (status === 'online') {
      isOnline = '1';
    }
    if (status === 'offline') {
      isOnline = '2';
    }
    if (!patchModalData || !patchModalData.length) {
      setPatchModalData(null);
      return;
    }
    api.updateStatus({
      productNormalIds: patchModalData.map(item => item.productNormalId).join(','),
      isOnline,
    }).then(() => {
      message.success('批量操作成功！');
      setPatchModalData(null);
      setPatchModalVisible(false);
      reloadData();
    }).catch(() => {
      message.error('批量操作失败！');
    });
  }

  function handlePageChange(page) {
    setCurrent(page);
  }

  const newDataSource = fillData(dataSource, pageSize, current, rowKey);
  return (
    <div>
      <Infos
        selectedRowKeys={selectedRowKeysS}
        clearSelected={() => setSelectedRowKeysS([])}
      />
      <UpdateModal
        visible={updateModalVisible}
        status={updateStatus}
        data={updateModalData}
        onOk={handleOkUpdateModal}
        onCancel={handleCancelUpdateModal}
      />
      <Table
        dataSource={newDataSource}
        rowSelection={rowSelection}
        pagination={false}
        rowClassName={record => (record.productNormalId ? '' : 'mi-empty-row')}
      >
        <Column title="序号" dataIndex="__miTableOrder" key="__miTableOrder" />
        <Column title="基础产品编号" dataIndex="productBaseId" key="productBaseId" />
        <Column title="运营商" dataIndex="spType" key="spType" render={text => getOperatorsDesc(text)} />
        <Column title="省份" dataIndex="areaCode" key="areaCode" />
        <Column title="产品名称" dataIndex="productName" key="productName" />
        <Column
          title="状态"
          dataIndex="isOnline"
          key="isOnline"
          render={text => (
            <>
              {getDotSpan(text)}
              <span>
                {' '}
                {
                  text
                }
              </span>
            </>
          )}
        />
        <Column title="更新时间" dataIndex="updateTime" key="updateTime" />
        <Column
          title="操作"
          key="action"
          render={(_, record) => (
            record.isOnline
              ? (
                <span>
                  {/* eslint-disable */}
                  <a href="javascript:;" disabled={record.isOnline !== "下线"} onClick={() => handleStatus(record, 'online')}>上线</a>
                  <Divider type="vertical" />
                  <a href="javascript:;" disabled={record.isOnline !== "上线"} onClick={() => handleStatus(record, 'offline')}>下线</a>
                  {/* eslint-enable */}
                </span>
              ) : null
          )}
        />
      </Table>
      <TableFooter
        popVisible={patchPopVisible}
        togglePopVisible={togglePatchPopVisible}
        onPatchOnline={() => handlePatchStatus('online')}
        onPatchOffline={() => handlePatchStatus('offline')}
        total={Number.parseInt(total, 10)}
        current={current}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        selectedRowKeys={selectedRowKeysS}
      />
      <PatchModal
        visible={patchModalVisible}
        data={patchModalData}
        status={patchStatus}
        onCancel={() => setPatchModalVisible(false)}
        onOk={handleOkPatchModal}
      />
    </div>
  );
}

ListTable.defaultProps = {
  conditions: null,
};

ListTable.propTypes = {
  conditions: PropTypes.shape({}),
};
export default ListTable;
