import moment from 'moment';
import Cookies from 'js-cookie';

export function unixTimeToMoment(uTime) {
  return moment.unix(uTime);
}

export function momentToUnixTime(mTime) {
  return mTime.unix();
}

export function getMomentToday() {
  return moment()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
}

export function getUnixTimeToday() {
  return getMomentToday().unix();
}

export function getDate() {
  return moment().format('YYYY-MM-DD');
}

export function getNDate(n = 0) {
  return getMomentToday().subtract(n, 'days');
}

export function dateToMoment(dateStr) {
  return moment(dateStr);
}

export function momentToDate(momentObj) {
  return momentObj.format('YYYY-MM-DD');
}

export function getUnixTimeRange(dateStrs) {
  return [
    momentToUnixTime(dateToMoment(dateStrs[0])),
    momentToUnixTime(dateToMoment(dateStrs[1]).add(1, 'days')),
  ];
}

export function getUserInfo() {
  const userId = Cookies.get('userId');
  const nickname = Cookies.get('nickname');
  const serviceToken = Cookies.get('nickname');
  return {
    userId,
    nickname,
    serviceToken,
  };
}

export function getLogoutUrl() {
  const userInfo = getUserInfo();
  const logoutUrl = process.env.REACT_APP_LOGOUT_URL || '';
  const logoutHref = logoutUrl.replace(/__TOBE_REPALCE_USERID__/, userInfo.userId);
  return logoutHref;
}
