import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Divider } from 'antd';
import './UpdateModal.scss';

export default function UpdateModal({
  visible, data, onCancel, onOk, status,
}) {
  if (!data) return null;
  const statusOnline = <span style={{ color: '#1890FF' }}>上线</span>;
  const statusOffline = <span style={{ color: '#FF3851' }}>下线</span>;
  let statusFrom = null;
  let statusTo = null;
  if (status === 'offline') {
    statusFrom = statusOnline;
    statusTo = statusOffline;
  }
  if (status === 'online') {
    statusFrom = statusOffline;
    statusTo = statusOnline;
  }
  return (
    <div>
      <Modal
        className="mi-modal"
        visible={visible}
        footer={null}
        mask={false}
        closable={false}
        destroyOnClose
        width={280}
        centered
      >
        <div className="mi-modal-body">
          <h3>产品状态更新</h3>
          <p>
            基础产品编号：
            {data.productBaseId}
          </p>
          <p>
            运营商：
            {data.spType}
          </p>
          <p>
            省份：
            {data.areaCode}
          </p>
          <p>
            产品名称：
            {data.productName}
          </p>
          <p>
            <span className="mi-little-circle blue" />
            {' '}
            <b>操作</b>
            {' '}
            {statusFrom}
            {' '}
            <b>改为</b>
            {' '}
            {statusTo}
          </p>
        </div>
        <div className="mi-modal-footer">
          <button type="button" onClick={onCancel}>取消</button>
          <Divider type="vertical" />
          <button type="button" className="ensure" onClick={() => onOk(status)}>确定</button>
        </div>
      </Modal>
    </div>
  );
}

UpdateModal.defaultProps = {
  visible: false,
  data: null,
  status: undefined,
};

UpdateModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({}),
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  status: PropTypes.string,
};
