import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Row, Col, Form, Input, Select, DatePicker,
} from 'antd';
import moment from 'moment';
import './index.scss';
import operators from '../../config/operators.json';
import provinces from '../../config/provinces.json';
import status from '../../config/status.json';

const { Option } = Select;
const initValues = {
  productBaseId: undefined, // 基本产品编号
  areaCode: undefined, // 省份
  status: undefined, // 状态
  spType: undefined, // 运营商
  productName: undefined, // 产品名称
  startTm: undefined, // 开始时间
  endTm: undefined, // 结束时间
};

export default function Condition({ onSearch }) {
  const [expand, setExpand] = useState(false);
  const [tempDate, setTempDate] = useState(undefined);
  const [formValues, setFormValues] = useState(initValues);
  function handleValueChange(name, value) {
    // 譬如antd Select的onChange事件不传event参数
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  function handleChange(e) {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  }
  function handleDateChange(date) {
    setTempDate(date);
  }

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  function handleSearch() {
    let startTm;
    let endTm;
    if (tempDate) {
      // 必须克隆日期对象，不然会修改原对象，有bug
      startTm = moment(tempDate[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      endTm = moment(tempDate[1]).startOf('day').add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    }
    onSearch({
      ...formValues,
      startTm,
      endTm,
    });
  }
  function handleReset() {
    setFormValues(initValues);
    setTempDate(undefined);
  }
  useEffect(() => {
    // 初始化查询
    handleSearch();
  }, []);
  return (
    <Form className="condition">
      <div className="condition-fields">
        <Row>
          <Col span={9} style={{ width: 300 }}>
            <Form.Item label="基础产品编号">
              <Input
                placeholder="请输入"
                name="productBaseId"
                value={formValues.productBaseId}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={5} style={{ width: 200 }}>
            <Form.Item label="省份">
              <Select
                showSearch
                filterOption={(inputValue, option) => (
                  option.props.children.indexOf(inputValue) > -1
                  || option.props.value.indexOf(inputValue) > -1
                )}
                placeholder="请选择"
                value={formValues.areaCode}
                onChange={value => handleValueChange('areaCode', value)}
              >
                {provinces.map(
                  item => <Option value={item.value} key={item.value}>{item.text}</Option>,
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} style={{ width: 200 }}>
            <Form.Item label="状态">
              <Select
                placeholder="请选择"
                value={formValues.status}
                onChange={value => handleValueChange('status', value)}
              >
                {status.map(
                  item => <Option value={item.value} key={item.value}>{item.text}</Option>,
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} style={{ width: 200 }}>
            <Form.Item label="运营商">
              <Select
                placeholder="请选择"
                value={formValues.spType}
                onChange={value => handleValueChange('spType', value)}
              >
                {operators.map(
                  item => <Option value={item.value} key={item.value}>{item.text}</Option>,
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {
          expand ? (
            <Row>
              <Col span={12} style={{ width: 300 }}>
                <Form.Item label="产品名称">
                  <Input
                    name="productName"
                    placeholder="请输入"
                    value={formValues.productName}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={{ width: 400 }}>
                <Form.Item label="更新时间">
                  <DatePicker.RangePicker
                    name="tempDate"
                    placeholder={['开始日期', '结束日期']}
                    value={tempDate}
                    onChange={handleDateChange}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col span={7} />
            </Row>
          ) : null
        }
      </div>
      <div className="condition-search">
        <Button type="primary" onClick={handleSearch}>查询</Button>
        <Button style={{ marginLeft: 8 }} onClick={handleReset}>
          重置
        </Button>
        {/* eslint-disable-next-line */}
        <a
          style={{ marginLeft: 8, fontSize: 12 }}
          onClick={() => setExpand(!expand)}
        >
          { expand ? '收起' : '展开' }
          <Icon type={expand ? 'up' : 'down'} />
        </a>
      </div>
    </Form>
  );
}

Condition.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
