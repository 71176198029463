import React, { useState, useEffect } from 'react';
import { LocaleProvider, Layout } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import Sider from './components/Sider';
import Header from './components/Header';
import Content from './components/Content';
import './App.scss';
import { getUserInfo, getLogoutUrl } from './utils';
import api from './api';

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [islogin, setIsLogin] = useState(false); // 是否登录
  const [authority, setAuthority] = useState(false); // 是否有权限
  useEffect(() => {
    api.isLogin().then((resData = {}) => {
      // 200：已登录且有权限 501：未登录  502：已登录无权限 500：系统异常
      const { status } = resData;
      if (status === 200) { // 已登录且有权限
        setIsLogin(true);
        setAuthority(true);
      } else if (status === 501) { // 未登录
        api.login();
      } else if (status === 502) { // 已登录无权限
        setIsLogin(true);
        setAuthority(false);
      } else {
        throw new Error('未获取到相关登录信息');
      }
    }).catch((e) => {
      console.log('验证权限出错！', e);
      api.login();
    });
  }, []);
  function toggle() {
    setCollapsed(!collapsed);
  }
  const userInfo = getUserInfo();
  const logoutHref = getLogoutUrl();

  let render = <div>未登录</div>;
  if (islogin && authority) {
    // 已登录，有权限
    render = (
      <LocaleProvider locale={zhCN}>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsed={collapsed} />
          <Layout>
            <Header collapsed={collapsed} toggle={toggle} />
            <Content />
          </Layout>
        </Layout>
      </LocaleProvider>
    );
  }
  if (islogin && !authority) {
    // 已登录，无权限
    render = (
      <div>
        无操作权限
        <br />
        <a
          style={{ color: '#1890FF', cursor: 'pointer' }}
          href={logoutHref}
        >
          {userInfo.userId && '退出'}
        </a>
        <span style={{ paddingLeft: 8, paddingRight: 21 }}>{userInfo.nickname || '未登录'}</span>
      </div>
    );
  }
  return render;
}

export default App;
