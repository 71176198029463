import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

export default function Infos({ selectedRowKeys, clearSelected }) {
  return (
    <Alert
      style={{
        display: selectedRowKeys.length ? '' : 'none',
      }}
      type="info"
      showIcon
      message={(
        <React.Fragment>
          已选择
          <span
            style={{
              color: '#1890FF',
            }}
          >
            {' '}
            {selectedRowKeys.length}
            {' '}
          </span>
          项
          {/* eslint-disable */}
          <a
            href="javascript:;"
            onClick={clearSelected}
            style={{
              marginLeft: '15px'
            }}
          >
            清空
          </a>
          {/* eslint-enable */}
        </React.Fragment>
      )}
    />
  );
}

Infos.defaultProps = {
  selectedRowKeys: [],
  clearSelected: () => {},
};

Infos.propTypes = {
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  clearSelected: PropTypes.func,
};
