import React from 'react';
import { Layout, Breadcrumb } from 'antd';
import Detail from '../Detail';
import { getUserInfo } from '../../utils';

const contentStyle = {
  borderTop: '1px solid #F0F2F5',
  minHeight: 280,
};

const descStyle = {
  padding: '18px 32px',
  paddingLeft: '32px',
  backgroundColor: '#fff',
};

export default function Content() {
  const userInfo = getUserInfo();
  return (
    <Layout.Content style={contentStyle}>
      <div style={descStyle}>
        <Breadcrumb style={{ margin: '0 0 18px 0' }}>
          <Breadcrumb.Item>流量商户后台</Breadcrumb.Item>
          <Breadcrumb.Item>产品管理</Breadcrumb.Item>
        </Breadcrumb>
        <h2 style={{ margin: 0 }}>
          产品管理-
          { userInfo.nickname || '未登录' }
        </h2>
      </div>
      <Detail />
    </Layout.Content>
  );
}
