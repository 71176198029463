import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Layout } from 'antd';
import { getUserInfo, getLogoutUrl } from '../../utils';

const headerStyle = {
  background: '#fff',
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
};

const iconStyle = {
  marginLeft: '20px',
};

export default function Header({ collapsed, toggle }) {
  const userInfo = getUserInfo();
  const logoutHref = getLogoutUrl();
  return (
    <Layout.Header style={headerStyle}>
      <div>
        <Icon
          type={collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={toggle}
          style={iconStyle}
        />
      </div>
      <div>
        <a
          style={{ color: '#1890FF', cursor: 'pointer' }}
          href={logoutHref}
        >
          {userInfo.userId && '退出'}
        </a>
        <span style={{ paddingLeft: 8, paddingRight: 21 }}>{userInfo.nickname || '未登录'}</span>
      </div>
    </Layout.Header>
  );
}

Header.defaultProps = {
  collapsed: false,
  toggle: () => {},
};

Header.propTypes = {
  collapsed: PropTypes.bool,
  toggle: PropTypes.func,
};
