import axios from 'axios';
import qs from 'qs';
import config from '../config';
import { getLogoutUrl } from '../utils';

const ax = axios.create({
  baseURL: config.baseUrl,
  timeout: config.timeout,
  withCredentials: true,
  // 后台要求application/x-www-form-urlencoded格式
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  // 配合application/x-www-form-urlencoded，需要对post参数做一下转换
  // 注意transformRequest只对'PUT', 'POST', and 'PATCH'有效
  transformRequest: [
    data => qs.stringify(data),
  ],
});

const axUpload = axios.create({
  baseURL: config.baseUrl,
  timeout: config.timeout,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

function loginGet(url, param = {}) {
  return ax
    .get(url, { params: param })
    .then(jsonData => jsonData.data)
    .catch((err = '登录错误') => {
      const { response } = err;
      if (response && response.status === 401) {
        // 需要登录
        const { loginUrl } = response.data;
        console.log('正在真401登录!', loginUrl);
        return loginUrl;
      }
      console.log('登录接口报错，无能为力了！', err);
      throw (err);
    });
}

export function login() {
  loginGet('/flowOrderUnity/login', {
    callback: process.env.REACT_APP_HOST,
  }).then((loginUrl) => {
    window.location.href = loginUrl;
  });
}

// passport登录
function errLogin(err = '未知错误') {
  const { response } = err;
  if (response && response.status === 401) {
    // 需要登录
    console.log('这是普通接口401需要登录!');
    login();
  }
  throw (err);
}

/**
 * 判断后台是否报错
 * @param {} jsonData
 */
function judgeResponse(jsonData) {
  const { status: systemStatus } = jsonData.data;
  // 200为正常返回数据 501为未登录 502为无权限
  if (systemStatus === 200 || systemStatus === 501 || systemStatus === 502) {
    return jsonData;
  }
  if (systemStatus === -1) {
    // 已被禁用，需要退出
    window.location.href = getLogoutUrl();
  }
  throw (jsonData);
}

export function get(url, param = {}) {
  return ax
    .get(url, { params: param })
    .then(judgeResponse) // 判断后台的错误，譬如500和-1
    .then(jsonData => jsonData.data)
    .catch(errLogin); // http级别的错误，譬如401重定向
}

export function post(url, param) {
  return ax.post(url, param).then(jsonData => jsonData.data);
}

export function upload(url, param) {
  return axUpload.post(url, param).then(jsonData => jsonData.data);
}
