import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Divider, Table } from 'antd';
import './UpdateModal.scss';

const { Column } = Table;

export default function PatchModal({
  visible, data, onCancel, onOk, status,
}) {
  if (!data) return null;
  const statusOnline = <span style={{ color: '#1890FF' }}>上线</span>;
  const statusOffline = <span style={{ color: '#FF3851' }}>下线</span>;
  let statusFrom = null;
  let statusTo = null;
  let onlineStatus;
  if (status === 'online') {
    statusFrom = statusOnline;
    statusTo = statusOffline;
    onlineStatus = 'offline';
  }
  if (status === 'offline') {
    statusFrom = statusOffline;
    statusTo = statusOnline;
    onlineStatus = 'online';
  }
  return (
    <div>
      <Modal
        className="mi-modal"
        visible={visible}
        footer={null}
        mask={false}
        closable={false}
        destroyOnClose
        width={486}
        centered
      >
        <div className="mi-modal-body">
          <h3>产品状态更新</h3>
          <Table
            className="patch-table"
            rowKey="productNormalId"
            dataSource={data}
            pagination={false}
          >
            <Column
              title="基础产品编号"
              dataIndex="productBaseId"
              key="productBaseId"
            />
            <Column
              title="运营商"
              dataIndex="spType"
              key="spType"
              width={80}
            />
            <Column
              title="省份"
              dataIndex="areaCode"
              key="areaCode"
              width={80}
            />
            <Column
              title="产品名称"
              dataIndex="productName"
              key="productName"
              width={120}
            />
          </Table>
          <p style={{ marginTop: '20px' }}>
            <span className="mi-little-circle blue" />
            {' '}
            <b>操作</b>
            {' '}
            将以上
            {' '}
            <span style={{ fontSize: '6px', color: '#1890FF' }}>{data.length}</span>
            {' '}
            个产品的状态由
            {' '}
            {statusFrom}
            {' '}
            <b>改为</b>
            {' '}
            {statusTo}
          </p>
        </div>
        <div className="mi-modal-footer mi-modal-footer-patch">
          <button type="button" onClick={onCancel}>取消</button>
          <Divider type="vertical" />
          <button type="button" className="ensure" onClick={() => onOk(onlineStatus)}>确定</button>
        </div>
      </Modal>
    </div>
  );
}

PatchModal.defaultProps = {
  visible: false,
  data: null,
  status: undefined,
};

PatchModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  status: PropTypes.string,
};
